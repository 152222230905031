import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class ServiceL73Page extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="L73"
          description="View route information and buy your ticket for the L73 late bus service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">L73 Late Bus service</h1>
              <p>Rodillian Academy - Hunslet Church Street Morrisons</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="L73 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1rayGyhaoFpvKIifY6KYeI5LA0ZmH9ug&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Rodillian Academy
                </p>
                <p>
                  From Long Thorpe Lane, Lingwell Gate Lane, Middleton Lane, Thorpe Lane, Middleton Park Avenue, Ring Road, Throstle Road North, Cranmore Road, Raylands Way, Broom Place, Ring Road Middleton, Middleton Road, East Grange Drive, Belle Isle Road, Balm Road, The Oval, Church Street.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Rodillian Academy</td>
                      <td>DEP</td>
                      <td>1600</td>
                    </tr>
                    <tr>
                      <td>Hunslet Church Street Morrisons</td>
                      <td>ARR</td>
                      <td>1623</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default ServiceL73Page
